@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;400;600;800&display=swap");

.App {
  padding: 0;
  margin: 0;

  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100vw;

  font-family: "Nunito Sans", "Trebuchet MS", Arial, sans-serif;
  font-weight: 400;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  overflow: scroll;
  background-color: rgb(243, 236, 222);

  // TODO: main color variables
  color: rgb(55, 41, 61);
}

/* Remove scrollbar visibility across all browsers */
* {
  scrollbar-width: none;
  box-sizing: border-box;
}

*::-webkit-scrollbar {
  width: 0;
  height: 0;
  -ms-overflow-style: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
