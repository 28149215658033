.about_image_wrapper {
  display: flex;
  justify-content: center;

  .responsive {
    border: 2px solid rgb(101, 56, 119);
    border-radius: 5px;
    width: 100%;
    max-width: 500px;
    height: auto;
  }
}
