@media only screen and (min-width: $tablet-breakpoint) {
  .layout-container {
    display: flex;
    flex-grow: 1;
  }
}

.main {
  flex: 4;
  /* change to show main background */
  // background-color: dodgerblue;
}

.sidebar {
  flex: 1;
}

.main,
.sidebar {
  padding: 1rem;
  max-height: 100vh;
  overflow: scroll;
}

@media only screen and (min-width: $mobile-breakpoint) {
  .main,
  .sidebar {
    padding: 1rem 1rem 0 1rem;
  }
}

// @media only screen and (min-width: $tablet-breakpoint) {
//   .main,
//   .sidebar {
//     padding: 2em 2rem 0 2rem;
//   }
// }
