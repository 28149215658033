.tile {
  transition: ease-in-out 0.1s;
  overflow: hidden;
  //   border-radius: 5px;
  //   border: rgb(116, 72, 116) 2px solid;
}

.tile:hover {
  cursor: pointer;
  filter: grayscale(75%);
  transform: scale(0.97);
}
